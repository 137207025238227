import {TackStatus} from '../../shared/type';

export const tackTypes = ['Bridle', 'Girth', 'Halter', 'Rug', 'Saddle', 'Stirrups', 'Other'];

export const UNKNOWN_STATUS = 'UNKNOWN';

export const tackStatusLabels: Record<TackStatus, string> = {
  [TackStatus.ACTIVE]: 'ACTIVE',
  [TackStatus.PENDING_TRANSFER]: 'PENDING TRANSFER',
  [TackStatus.STOLEN]: 'STOLEN',
};

export {TackStatus};
