import * as yup from 'yup';

export const tackDetailsFormSchema = yup.object({
  tackName: yup.string().required().label('Item Name'),
  tackSize: yup.string().required().label('Size'),
  tackBrand: yup.string().required().label('Brand'),
  tackColour: yup.string().required().label('Colour'),
  tackType: yup.string().required().label('Type'),
  tackDescription: yup.string().required().label('Description'),
});
