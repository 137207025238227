
import {defineComponent} from 'vue';
import {Form, Field, ErrorMessage, configure} from 'vee-validate';
import * as yup from 'yup';
import PageTitle from '@/components/layout/PageTitle.vue';
import {useUserStore} from '../stores/UserStore';
import {validationConfigDefault} from '@/validation/config';

configure(validationConfigDefault);

export default defineComponent({
  name: 'LoginView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
  },
  props: ['restrictedAccess'],
  setup() {
    const userStore = useUserStore();

    const loginFormSchema = yup.object({
      userEmail: yup.string().required().email().label('Email Address'),
      userPassword: yup.string().required().min(8).label('Password'),
    });

    return {userStore, loginFormSchema};
  },
  data: () => {
    return {
      email: '',
      password: '',
      isFailed: false,
    };
  },
  methods: {
    async login() {
      const result = await this.userStore.login(this.email, this.password);
      if (result) {
        if (await this.userStore.isLoggedIn()) {
          this.$router.push('/account');
        }
      } else {
        this.isFailed = true;
        console.log('USER IS NOT EXISTS OR WRONG EMAIL OR PASSWORD');
      }
    },
  },
});
