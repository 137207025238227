
import {ErrorMessage, Field, Form, configure} from 'vee-validate';
import {Ref, defineComponent, ref, watchEffect} from 'vue';
import {useRoute, useRouter} from 'vue-router';

import PageTitle from '@/components/layout/PageTitle.vue';
import PageLoader from '@/components/PageLoader.vue';

import {searchUserByEmailSchema} from '@/validation/tackTransfer';
import {loadTackRequest, searchUserByEmail, tackTransfer, httpCodes} from '@/services/api';
import {getImageURL, getStatusLabel, isStolen} from '@/helpers';

import type {Tack} from '@/services/api';
import type {TransferRecipient, TransferRecipientError} from '@/services/api';
import {isAxiosError} from 'axios';
import {validationConfigTackTransfer} from '@/validation/config';

configure(validationConfigTackTransfer);

const initialtTack: Tack = {
  id: '',
  tackName: '',
  tackNumber: '',
  tackStatus: 1,
  tackSize: '',
  tackBrand: '',
  tackColour: '',
  tackType: '',
  tackDescription: '',
  tackImage: '',
  tackOwnerId: '',
};

export default defineComponent({
  name: 'TackTransferView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
    PageLoader,
  },
  async setup() {
    const route = useRoute();
    const router = useRouter();
    const step = ref<number>(1);
    const email = ref<string>('');
    const recipient = ref<TransferRecipient>();
    const searchRecipientError = ref<TransferRecipientError | null>(null);
    const transferRecipientError = ref<TransferRecipientError | null>(null);

    const isLoading = ref(true);
    const tack: Ref<Tack> = ref({...initialtTack});

    watchEffect(async () => {
      if (!route.params.id) {
        return;
      }

      const tackData = await loadTackRequest(route.params.id as string);
      if (tackData) {
        tack.value = tackData;

        isLoading.value = false;
      } else {
        router.push('/account');
      }
    });

    const changeStep = (stepTo: number) => {
      step.value = stepTo;
    };

    async function onSearchUser() {
      isLoading.value = true;

      const transferRecipientResult = await searchUserByEmail(email.value).catch((e) => {
        searchRecipientError.value = e.message;
        isLoading.value = false;
      });

      if (!transferRecipientResult) {
        router.push('/account');
        return;
      }

      isLoading.value = false;
      if ('status' in transferRecipientResult) {
        searchRecipientError.value = transferRecipientResult;
      } else {
        recipient.value = transferRecipientResult;
        step.value = 2;
      }
    }

    async function onTransferConfirm() {
      isLoading.value = true;

      try {
        const result = await tackTransfer(tack.value.id, recipient.value?.userId as string);

        if (!result) {
          throw Error('Something wrong with transfer');
        } else {
          isLoading.value = false;

          step.value = 3;
        }
      } catch (error: any) {
        if (isAxiosError(error) && error.response?.status) {
          transferRecipientError.value = {status: error.response.status, message: error.response.data.message};
        } else {
          transferRecipientError.value = {status: 500, message: error.message};
        }
        isLoading.value = false;
      }
    }

    function resetErrors() {
      searchRecipientError.value = null;
      transferRecipientError.value = null;
    }

    function onBack() {
      resetErrors();
      changeStep(1);
    }

    return {
      isLoading,
      schema: searchUserByEmailSchema,
      step,
      searchRecipientError,
      transferRecipientError,
      // tack info
      tack,
      getImageURL,
      isStolen: isStolen(tack.value.tackStatus),
      statusLabel: getStatusLabel(tack.value.tackStatus),
      // methods
      onSearchUser,
      onTransferConfirm,
      onBack,
      // form fields
      email,
      recipient,
      // http codes
      NOT_FOUND: httpCodes.NOT_FOUND,
      NOT_PAID_MEMBER: httpCodes.FORBIDDEN,
    };
  },
});
