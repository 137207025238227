<template>
  <div id="page-header" class="page-header-wrapper sticky-header">
    <div class="header-bar">
      <div class="logo-wrapper">
        <a class="site-logo" href="https://tacktrack.com.au/">
          <img :src="headerLogo" width="152" height="110" sizes="152px" alt="Tack Track" />
        </a>
      </div>
      <ul id="primary-menu" class="main-nav">
        <li class="menu-item">
          <a href="https://tacktrack.com.au/">
            <span class="menu-item-text">
              <span class="menu-text">Home</span>
            </span>
          </a>
        </li>
        <li class="menu-item">
          <a
            href="https://tacktrack.com.au/features/"
            v-on:mouseover="overFeatures"
            v-on:mouseleave="leaveFeatures"
            :class="{hovered: featuresHovered}"
          >
            <span class="menu-item-text">
              <span class="menu-text">Features</span>
            </span>
          </a>
          <ul
            class="sub-nav"
            :style="{opacity: featuresHovered ? 1 : 0, visibility: featuresHovered ? 'visible' : 'hidden'}"
            v-on:mouseover="overFeatures"
            v-on:mouseleave="leaveFeatures"
          >
            <li class="menu-item">
              <a href="https://tacktrack.com.au/features/">
                <span class="menu-item-text"><span class="menu-text">Features</span></span></a
              >
            </li>
            <li class="menu-item">
              <a href="https://tacktrack.com.au/faq/">
                <span class="menu-item-text"><span class="menu-text">FAQ</span></span></a
              >
            </li>
          </ul>
        </li>

        <li class="menu-item">
          <a
            href="https://tacktrack.com.au/horse-tack-marking/"
            v-on:mouseover="overMarking"
            v-on:mouseleave="leaveMarking"
            :class="{hovered: markingHovered}"
          >
            <span class="menu-item-text">
              <span class="menu-text">Tack Marking</span>
            </span>
          </a>

          <ul
            class="sub-nav"
            :style="{opacity: markingHovered ? 1 : 0, visibility: markingHovered ? 'visible' : 'hidden'}"
            v-on:mouseover="overMarking"
            v-on:mouseleave="leaveMarking"
          >
            <li class="menu-item">
              <a href="https://tacktrack.com.au/shop/">
                <span class="menu-item-text"><span class="menu-text">Shop</span></span></a
              >
            </li>
            <li class="menu-item">
              <a href="https://tacktrack.com.au/horse-tack-marking/">
                <span class="menu-item-text"><span class="menu-text">How to mark your tack</span></span></a
              >
            </li>
            <li class="menu-item">
              <a href="https://tacktrack.com.au/find-a-tack-marker/">
                <span class="menu-item-text"><span class="menu-text">Find a Tack Marker</span></span></a
              >
            </li>
          </ul>
        </li>

        <li class="menu-item">
          <a href="https://tacktrack.com.au/blog/">
            <span class="menu-item-text">
              <span class="menu-text">Blog</span>
            </span>
          </a>
        </li>
        <li class="menu-item">
          <router-link :to="{name: 'Account'}" class="login-nav-btn">
            <span class="menu-item-text">
              <span class="menu-text">My Account</span>
            </span>
          </router-link>
        </li>
        <li class="menu-item">
          <a href="https://tacktrack.com.au/contact/">
            <span class="menu-item-text">
              <span class="menu-text">Contact Us</span>
            </span>
          </a>
        </li>
      </ul>
      <div class="tack-search-nav-btn-wrapper">
        <router-link :to="{name: 'Search'}" class="tack-search-nav-btn"><span>TACK SEARCH</span></router-link>
      </div>
      <div class="profile-nav-menu-wrapper">
        <div v-if="isLoggedIn()">
          <a @click="logout()" href="#" class="login-nav-btn">
            <span><font-awesome-icon icon="fa-solid fa-right-from-bracket" /> Logout</span>
          </a>
        </div>
        <div v-else>
          <router-link :to="{name: 'Login', params: {restrictedAccess: 0}}" class="login-nav-btn">
            <span><font-awesome-icon icon="fa-solid fa-user" /> Login</span>
          </router-link>
          <router-link :to="{name: 'Register'}" class="register-nav-btn">
            <span><font-awesome-icon icon="fa-solid fa-user" /> Register</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerLogo from '@/assets/images/logo-small.png';
import {useUserStore} from '@/stores/UserStore';

export default {
  name: 'PageHeader',
  props: {
    default: () => [],
  },
  data: function () {
    return {
      featuresHovered: false,
      markingHovered: false,
      headerLogo,
      isLoggedIn() {
        const userStore = useUserStore();
        const isLoggedIn = userStore.isLoggedIn();
        return isLoggedIn;
      },
      async logout() {
        const userStore = useUserStore();
        await userStore.logout();
        this.$router.push('/login/0');
      },
    };
  },
  methods: {
    overFeatures() {
      this.featuresHovered = true;
    },
    leaveFeatures() {
      this.featuresHovered = false;
    },
    overMarking() {
      this.markingHovered = true;
    },
    leaveMarking() {
      this.markingHovered = false;
    },
  },
};
</script>
