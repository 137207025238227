import {appConfig} from '@/config';
import {tackStatusLabels, UNKNOWN_STATUS, TackStatus} from '@/constants';
const IMAGE_STORE_URL = appConfig.imageStoreUrl;

export const getImageURL = (ownerId: string, imageName: string): string => {
  return `${IMAGE_STORE_URL}/${ownerId}/${imageName}`;
};

export const getStatusLabel = (status: TackStatus): string => {
  return tackStatusLabels[status] || UNKNOWN_STATUS;
};

export const isStolen = (status: TackStatus): boolean => status === TackStatus.STOLEN;
