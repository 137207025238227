// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.7.3_webpack@5.75.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&#038;ver=6.1.1);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600,700|Roboto+Condensed:400,600,700);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main{height:100%;display:flex;flex-direction:column}.slide-enter-active,.slide-leave-active{max-height:150px}.slide-enter-active{transition:all .5s ease-in}.slide-leave-active{transition:all .5s ease-out}.slide-enter-from,.slide-leave-to{opacity:0;max-height:0}.slide-enter-to,.slide-leave-from{opacity:1;max-height:auto}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
