
import {ErrorMessage, Field, Form, configure} from 'vee-validate';
import {Ref, defineComponent, ref, watchEffect} from 'vue';
import {useRoute, useRouter} from 'vue-router';

import PageTitle from '@/components/layout/PageTitle.vue';
import PageLoader from '@/components/PageLoader.vue';

import {tackDetailsFormSchema} from '@/validation/tackDetails';
import {loadTackRequest, editTackRequest} from '@/services/api';
import {tackTypes} from '@/constants';
import {getImageURL} from '@/helpers';

import type {Tack} from '@/services/api';
import {validationConfigDefault} from '@/validation/config';

type EditTack = Omit<Tack, 'tackNumber' | 'tackStatus'>;

const defaultTack: EditTack = {
  id: '',
  tackName: '',
  tackSize: '',
  tackBrand: '',
  tackColour: '',
  tackType: '',
  tackDescription: '',
  tackImage: '',
  tackOwnerId: '',
};

configure(validationConfigDefault);

export default defineComponent({
  name: 'TackEditView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
    PageLoader,
  },
  async setup() {
    const route = useRoute();
    const router = useRouter();

    let isLoading = ref(true);
    const tackMainPhoto = ref(null);
    const tackPreviewPhoto = ref('');
    let tackInitial: any;
    const tack: Ref<EditTack> = ref({...defaultTack});

    watchEffect(async () => {
      if (!route.params.id) {
        return;
      }

      const newTack = await loadTackRequest(route.params.id as string);
      if (newTack) {
        tack.value = newTack;
        tackInitial = {...newTack} as any;

        isLoading.value = false;
      } else {
        router.push('/account');
      }
    });

    async function editTackDetails(values: any) {
      isLoading.value = true;

      const changedTacksDetails: any = {};

      for (let formEntry in values) {
        if (tackInitial[formEntry] !== values[formEntry]) {
          changedTacksDetails[formEntry] = values[formEntry];
        }
      }

      const result = await editTackRequest(route.params.id as string, changedTacksDetails, tackMainPhoto).catch((e) => {
        isLoading.value = false;
      });

      if (result.status == true) {
        router.push('/account');
      } else {
        isLoading.value = false;
      }
    }

    function onPreviewMainPhoto(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = () => {
          tackPreviewPhoto.value = reader.result as string;
        };
        // eslint-disable-next-line
        tackMainPhoto.value = input.files[0] as any;
        reader.readAsDataURL(input.files[0]);
      }
    }

    return {
      isLoading,
      tackTypes,
      schema: tackDetailsFormSchema,
      tack,
      editTackDetails,
      onPreviewMainPhoto,
      tackMainPhoto,
      tackPreviewPhoto,
      getImageURL,
    };
  },
  methods: {
    chooseMainPhoto() {
      // eslint-disable-next-line
      (this.$refs as any).mainPhotoFileInput.click();
    },
  },
});
