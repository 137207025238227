<template>
  <div id="page-title" class="page-title-wrapper">
    <div class="page-title-internal-container std-wrapper">
      <div class="page-title-head-wrapper">
        <h1>{{ title }}</h1>
      </div>
      <div class="page-title-breadcrumbs-wrapper">
        <ul class="page-title-breadcrumbs-nav">
          <li>
            <a href="https://tacktrack.com.au/" title="Home"><span>Home</span></a>
          </li>
          <li>
            <span>{{ title }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: String,
  },
};
</script>

<style scoped></style>
