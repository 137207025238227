
import {defineComponent, ref, onMounted, onUnmounted} from 'vue';
import {useStripe, StripeElement} from 'vue-use-stripe';
import PageTitle from '@/components/layout/PageTitle.vue';
import {Form, Field, ErrorMessage, configure} from 'vee-validate';
import {useTackRegisterStore} from '../stores/TackRegisterStore';
import {useUserStore} from '../stores/UserStore';
import axios from 'axios';
import {appConfig} from '@/config';
import {StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement, StripeElementStyle} from '@stripe/stripe-js';
import {tackDetailsFormSchema} from '@/validation/tackDetails';
import {tackRegisterUserDetailsSchema} from '@/validation/userRegister';
import PageLoader from '@/components/PageLoader.vue';
import {tackTypes} from '@/constants';
import pricingGraphic from '@/assets/images/pricing-graphic.svg';
import securePayment from '@/assets/images/secure-payment.png';
import {getVerificationToken} from '@/services/api';
import {validationConfigDefault} from '@/validation/config';

const API_BASE_URL = appConfig.apiBaseUrl;
const STRIPE_PUBLIC_KEY = appConfig.stripePublicKey;
const FRANKIE_BACKEND_URL = appConfig.frankieBackendUrl;

type Step = {
  key: StepIds;
  number: number;
  nextStep?: StepIds;
  title: string;
};

enum StepIds {
  'details',
  'idValidation',
  'payment',
  'registerTack',
}

const stepsDefault: Step[] = [
  {
    key: StepIds.details,
    number: 1,
    title: 'YOUR DETAILS',
    nextStep: StepIds.idValidation,
  },
  {
    key: StepIds.idValidation,
    number: 2,
    title: 'ID VALIDATION',
    nextStep: StepIds.payment,
  },
  {
    key: StepIds.payment,
    number: 3,
    title: 'PAYMENT',
    nextStep: StepIds.registerTack,
  },
  {
    key: StepIds.registerTack,
    number: 4,
    title: 'REGISTER TACK',
  },
];

const stepsForVerified: Step[] = [
  {
    key: StepIds.details,
    number: 1,
    title: 'YOUR DETAILS',
    nextStep: StepIds.payment,
  },
  {
    key: StepIds.payment,
    number: 2,
    title: 'PAYMENT',
    nextStep: StepIds.registerTack,
  },
  {
    key: StepIds.registerTack,
    number: 3,
    title: 'REGISTER TACK',
  },
];

const cardElementStyle = {
  base: {
    'iconColor': '#c48f1b',
    'color': '#222222',
    'fontWeight': '500',
    'fontFamily': 'Roboto, Open Sans, Segoe UI, sans-serif',
    'fontSize': '16px',
    'fontSmoothing': 'antialiased',
    ':-webkit-autofill': {
      color: '#fce883',
    },
    '::placeholder': {
      color: '#AAAAAA',
    },
  },
  invalid: {
    iconColor: '#c21b1b',
    color: '#c21b1b',
  },
};

configure(validationConfigDefault);

function injectFrankieScript() {
  const element = document.createElement('script');
  // set the type attribute
  element.type = 'application/javascript';
  // make the script element load file
  element.src = 'https://assets.frankiefinancial.io/onboarding/v4/ff-onboarding-widget.umd.min.js';
  // finally insert the element to the body element in order to load the script
  document.body.appendChild(element);
}

const successEvent = new Event('onFFCheckSuccess');

window.ffCheckSuccessTrigger = function () {
  window.dispatchEvent(successEvent);
};

const widgetConfiguration = {
  frankieBackendUrl: FRANKIE_BACKEND_URL,
  documentTypes: ['DRIVERS_LICENCE', 'PASSPORT', 'NATIONAL_HEALTH_ID'],
  idScanVerification: false,
  checkProfile: 'auto',
  maxAttemptCount: 5,
  googleAPIKey: false,
  phrases: {
    document_select: {
      title: 'Custom Text Here: Choose your ID',
      hint_message: "Choose which ID you'd like to provide.",
    },
  },
  requestAddress: {acceptedCountries: ['AUS']},
  consentText: "I agree with the terms described in the Consent section of the Company's webpage",
  successScreen: {
    ctaUrl: 'javascript:ffCheckSuccessTrigger()',
    ctaText: 'NEXT PAYMENT',
  },
  failureScreen: {
    ctaUrl: 'javascript:onFFCheckFailureTrigger()',
    ctaText: 'FAILURE',
  },
};

export default defineComponent({
  name: 'TackRegisterView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
    StripeElement,
    PageLoader,
  },
  async setup() {
    window.addEventListener('onFFCheckSuccess', onFFCheckSuccessHandler, false);

    const {
      stripe,
      elements: [cardNumber, cardExpiry, cardCvc],
    } = useStripe({
      key: STRIPE_PUBLIC_KEY,
      elements: [
        {
          type: 'cardNumber',
          options: {
            style: cardElementStyle as StripeElementStyle,
            placeholder: '1234 1234 1234 1234',
          },
        },
        {
          type: 'cardExpiry',
          options: {style: cardElementStyle as StripeElementStyle},
        },
        {
          type: 'cardCvc',
          options: {style: cardElementStyle as StripeElementStyle},
        },
      ],
    });

    onMounted(async () => {
      console.log('Mounted');

      const token = await getVerificationToken();

      if (token) {
        ffToken.value = token;
      }

      (cardNumber.value as StripeCardNumberElement).on('change', function (event) {
        if (event.empty || event.error) {
          console.log('Card number is incorrect');
          cardNumberIsIncorrect = true;
        } else {
          cardNumberIsIncorrect = false;
        }
      });
      (cardExpiry.value as StripeCardExpiryElement).on('change', function (event) {
        if (event.empty || event.error) {
          console.log('Card expiry is incorrect');
          cardExpiryIsIncorrect = true;
        } else {
          cardExpiryIsIncorrect = false;
        }
      });
      (cardCvc.value as StripeCardCvcElement).on('change', function (event) {
        if (event.empty || event.error) {
          console.log('Card CVC is incorrect');
          cardCodeIsIncorrect = true;
        } else {
          cardCodeIsIncorrect = false;
        }
      });
    });

    onUnmounted(() => {
      window.removeEventListener('onFFCheckSuccess', onFFCheckSuccessHandler);
    });

    const tackRegisterStore = useTackRegisterStore();
    const userStore = useUserStore();

    await userStore.fetchUser();

    const user = {...userStore.user};
    const details = user.details ? {...user.details} : null;
    const isVerified = details && details.isVerified;

    const steps = isVerified ? stepsForVerified : stepsDefault;

    let currentStep = ref<Step>(steps[0]);

    injectFrankieScript();

    let cardNumberIsIncorrect = true;
    let cardExpiryIsIncorrect = true;
    let cardCodeIsIncorrect = true;

    let isLoading = ref(false);
    let isRegisteringTack = ref(false);

    const event = ref(null);

    let paymentError = ref('');
    let ffToken = ref('');

    async function onFFCheckSuccessHandler() {
      const details = {...tackRegisterStore.userDetails, isVerified: true};
      await tackRegisterStore.setUserDetails(details);

      takeNextStep();
    }

    const takeNextStep = () => {
      currentStep.value = steps.find((stp) => stp.key === currentStep.value?.nextStep) as Step;
    };

    const nextButtonText = () => {
      return steps.find((stp) => stp.key === currentStep.value?.nextStep)?.title;
    };

    const isBillingNeeded = async () => {
      const response = await userStore.isBillingNeeded();
      if (response && response.status && !response.isBillingNeeded) {
        currentStep.value = steps.find((stp) => stp.key === StepIds.registerTack) as Step;
        return false;
      }
      return true;
    };

    const showBilling = await isBillingNeeded();

    const processPayment = async () => {
      paymentError.value = '';
      let cardHolderName = '';

      const cardHoldersName = document.querySelector('[name="cardHolderName"]');
      if (!(cardHoldersName as HTMLInputElement).value) {
        paymentError.value = "Please enter Card holder's name.";
        return false;
      } else {
        cardHolderName = (cardHoldersName as HTMLInputElement).value;
      }

      if (cardNumberIsIncorrect) {
        paymentError.value = 'Please enter correct card number.';
        return false;
      }

      if (cardExpiryIsIncorrect) {
        paymentError.value = 'Please enter correct card expiry date.';
        return false;
      }

      if (cardCodeIsIncorrect) {
        paymentError.value = 'Please enter correct card CVV/CVC code.';
        return false;
      }

      if (event.value && (event.value as any).error) {
        console.log((event.value as any).error.message);
        paymentError.value = (event.value as any).error.message;
        return false;
      }

      isLoading.value = true;

      if (event.value && (event.value as any).complete) {
        console.log('Form filled complete');

        const userData = userStore.getUser;

        //const userName = (userData as any).firstName + ' ' + (userData as any).lastName;
        const userEmail = (userData as any).email;

        console.log(userData);

        const billingDetails = {
          name: cardHolderName,
          email: userEmail,
        };

        console.log('Trying to create payment method');

        const paymentMethodResult = await stripe.value?.createPaymentMethod({
          type: 'card',
          card: cardNumber.value,
          billing_details: billingDetails,
        });

        if (paymentMethodResult) {
          const paymentMethod = paymentMethodResult.paymentMethod;

          console.log(paymentMethod);

          if (paymentMethod && (paymentMethod as any).error) {
            console.error((paymentMethod as any).error);
          } else if (!paymentMethod?.id) {
            console.error('failed to get payment ID');
          } else {
            console.log('Processing payment');

            const response = await fetch(API_BASE_URL + '/user/payment', {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              credentials: 'include',
              body: JSON.stringify({
                paymentMethodId: paymentMethod?.id,
              }),
            });

            const result = await response.json();

            console.log(result);

            isLoading.value = false;

            if (result.status) {
              console.log('Payment successful:', result.paymentStatus);
              if (result.paymentStatus == 'succeeded') return true;
            } else {
              console.error('Payment error:', result.error);
              paymentError.value = result.error.raw.message;
            }
          }
        }
      }

      isLoading.value = false;
      return false;
    };

    return {
      // Steps
      steps,
      takeNextStep,
      StepIds,
      nextButtonText,
      // Details
      streetAddress: details ? details.address : '',
      postcode: details ? details.postcode : '',
      state: details ? details.state : 'New South Wales',
      suburb: details ? details.suburb : '',
      mobileNumber: details ? details.mobile : '',
      // Id Validation
      ffToken,
      isVerified,
      // Payment
      showBilling,
      currentStep,
      event,
      cardNumber,
      cardExpiry,
      cardCvc,
      processPayment,
      paymentError,
      stripe,
      // Tack
      tackTypes,
      tackRegisterStore,
      userStore,
      userSchema: tackRegisterUserDetailsSchema,
      tackSchema: tackDetailsFormSchema,
      isLoading,
      isRegisteringTack,
    };
  },
  data: () => {
    return {
      tackName: '',
      tackSize: '',
      tackBrand: '',
      tackColour: '',
      tackType: 'Saddle',
      tackDescription: '',
      tackMainPhoto: null,
      tackPreviewPhoto: '',
      pricingGraphic,
      securePayment,
      userTerms: undefined,
    };
  },
  methods: {
    highlightActiveStep(step: Step) {
      return this.currentStep?.key == step.key;
    },

    chooseMainPhoto() {
      (this.$refs as any).mainPhotoFileInput.click();
    },
    previewMainPhoto: function (event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = () => {
          this.tackPreviewPhoto = `url("${reader.result}")`;
          let prv = document.querySelector('#tack-preview-main-photo') as HTMLDivElement;
          prv.style.backgroundImage = this.tackPreviewPhoto;
          prv.style.display = 'block';
        };
        this.tackMainPhoto = input.files[0] as any;
        reader.readAsDataURL(input.files[0]);
      }
    },

    async registerTackUserDetails() {
      const userData = this.userStore.getUser;
      const userDetails = {
        userId: (userData as any).id,
        address: this.streetAddress,
        suburb: this.suburb,
        state: this.state,
        postcode: this.postcode,
        mobile: this.mobileNumber,
      };
      await this.tackRegisterStore.setUserDetails(userDetails);
      await this.userStore.setUserDetails(userDetails as any);

      this.takeNextStep();
    },

    async registerPaymentDetails() {
      const payment = await this.processPayment();

      if (payment) {
        this.takeNextStep();
      }
    },

    async registerTackDetails() {
      this.isRegisteringTack = true;

      try {
        const tackDetails = {
          tackName: this.tackName,
          tackSize: this.tackSize,
          tackBrand: this.tackBrand,
          tackColour: this.tackColour,
          tackType: this.tackType,
          tackDescription: this.tackDescription,
        };

        await this.tackRegisterStore.setTackDetails(tackDetails);

        const result = await this.registerTack();

        if (result.status == true) {
          this.$router.push('/account');
        }
        this.isRegisteringTack = false;
      } catch (e) {
        this.isRegisteringTack = false;
      }
    },

    async registerTack() {
      let valid = true;
      if (valid) {
        const formData = new FormData();

        const registerTackData = {
          userDetails: this.tackRegisterStore.userDetails,
          //paymentDetails: this.tackRegisterStore.paymentDetails,
          tackDetails: this.tackRegisterStore.tackDetails,
        };

        formData.append('mainTackPhoto', this.tackMainPhoto as any);
        formData.append('registerTackData', JSON.stringify(registerTackData));

        const res = await axios.post(API_BASE_URL + '/tack/register', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });

        return res.data;
      }
    },

    initFF() {
      setTimeout(() => {
        window.frankieFinancial.initialiseOnboardingWidget({
          applicantReference: this.userStore.user.id, /// the string reference that will be injected into this applicant's data, will be used to prefill data and can be used to request their details aftwerwards, both via Frankie API and Frankie Portal
          config: widgetConfiguration,
          width: '500px',
          height: '900px',
          ffToken: this.ffToken,
        });
      }, 200);
    },
  },
});
