import {defineStore} from 'pinia';
import {appConfig} from '@/config';
import axiosDefault from 'axios';

const API_BASE_URL = appConfig.apiBaseUrl;
const axios = axiosDefault.create({baseURL: API_BASE_URL, withCredentials: true});

export const useUserStore = defineStore('user', {
  state: () =>
    ({
      user: null,
      userDetails: null,
    } as any),

  getters: {
    getUser(): object {
      return this.user || {};
    },
  },

  actions: {
    async fetchUser() {
      const res = await axios.get('/user/profile');

      this.user = res.data;
    },

    async updateProfile(values: any) {
      const res = await axios.put('/user/profile', values);

      return res.data;
    },

    isLoggedIn(): boolean {
      if (this.user !== null) return true;
      return false;
    },

    async login(email: string, password: string) {
      const res = await fetch(API_BASE_URL + '/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      const user = await res.json();
      if (user.status === false) {
        return false;
      } else {
        this.user = user.data;
        return true;
      }
    },

    async register(firstName: string, lastName: string, email: string, password: string, role: string, reCaptchaToken: string) {
      const res = await fetch(API_BASE_URL + '/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          role: role,
          token: reCaptchaToken,
        }),
      });

      const data = await res.json();

      if (data.data && 'alreadyExists' in data.data) {
        this.user = null;
        return {alreadyExists: true};
      } else {
        this.user = data.data;
        return {alreadyExists: false};
      }
    },

    async setUserDetails(userDetails: JSON) {
      const response = await fetch(API_BASE_URL + '/user/update-details/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(userDetails),
      });
      const uD = await response.json();
      this.userDetails = uD;
      return this.userDetails;
    },

    async getUserDetails(userId: number) {
      const response = await fetch(API_BASE_URL + '/user/get-details/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({userId: userId}),
      });
      const uD = await response.json();

      this.userDetails = uD;
      return this.userDetails;
    },

    async logout() {
      const res = await fetch(API_BASE_URL + '/user/logout', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await res.json();

      this.user = null;
      this.userDetails = null;
    },

    async check() {
      const res = await fetch(API_BASE_URL + '/user/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await res.json();
      if (data.data.loggedIn === true) {
        this.user = data.data.user.payload;
      } else {
        this.user = null;
      }
    },

    async isBillingNeeded() {
      const res = await fetch(API_BASE_URL + '/user/isbillingneeded', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await res.json();

      return data;
    },
  },
});
