
import {defineComponent} from 'vue';
import PageTitle from '@/components/layout/PageTitle.vue';
import SearchForm from '@/components/search/SearchForm.vue';

export default defineComponent({
  name: 'SearchView',
  components: {
    PageTitle,
    SearchForm,
  },
});
