<template>
  <div id="page-footer" class="page-footer-wrapper">
    <div class="page-footer-internal-container std-wrapper">
      <div class="page-footer-content-wrapper">
        <section class="footer-first-content footer-content-section">
          <div align="center">
            <img decoding="async" style="margin-bottom: 40px; max-width: 70%" :src="footerLogo" />
          </div>
        </section>
        <section class="footer-second-content footer-content-section">
          <div class="footer-links-container">
            <div>
              <div class="footer-menu-header">TACK TRACK®</div>
              <ul class="footer-menu">
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/features/">Features</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/shop/">Shop</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/horse-tack-marking/">How to Mark Your Tack</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/find-a-tack-marker/">Find a Tack Marker</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/blog/">Blog</a>
                </li>
              </ul>
            </div>
            <div>
              <div class="footer-menu-header">Customer Service</div>
              <ul class="footer-menu">
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/support/">Support</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/terms-conditions/">Terms &amp; Conditions</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/privacy-policy/">Privacy Policy</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/about-tacktrack/">About Us</a>
                </li>
                <li class="menu-item">
                  <a href="https://tacktrack.com.au/contact/">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="footer-third-content footer-content-section">
          <div class="footer-social-icons">
            <a title="Facebook" href="https://www.facebook.com/tacktrackaustralia" target="_blank" class="">
              <img class="facebook-icon" :src="facebookIcon" alt="Facebook" />
            </a>
            <a title="Instagram" href="https://instagram.com/tacktrackau/" target="_blank" class="">
              <img class="instagram-icon" :src="instagramIcon" alt="Instagram" />
            </a>
          </div>
          <br /><br />
          <div align="center">
            © Copyright {{ new Date().getFullYear() }} Tack Track <br />
            <a href="https://www.showponycreative.com.au" target="_blank"> Designed by Show Pony Creative </a>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import footerLogo from '@/assets/images/logo-big.png';
import facebookIcon from '@/assets/images/icons/facebook-circled.svg';
import instagramIcon from '@/assets/images/icons/instagram-circled.svg';

export default {
  name: 'PageFooter',
  props: {
    default: () => [],
  },
  data: function () {
    return {
      footerLogo,
      facebookIcon,
      instagramIcon,
    };
  },
};
</script>

<style scoped></style>
