import {defineStore} from 'pinia';

export const useTackRegisterStore = defineStore('tackRegister', {
  state: () => {
    return {
      userDetails: {},
      paymentDetails: {},
      tackDetails: {},
    };
  },

  getters: {
    getUserDetails(): object {
      return this.userDetails;
    },
    getPaymentDetails(): object {
      return this.paymentDetails;
    },
    getTackDetailsDetails(): object {
      return this.tackDetails;
    },
  },

  actions: {
    async setUserDetails(data: object) {
      this.userDetails = data;
      return this.userDetails;
    },

    async setPaymentDetails(data: object) {
      this.paymentDetails = data;
      return this.paymentDetails;
    },

    async setTackDetails(data: object) {
      this.tackDetails = data;
      return this.tackDetails;
    },
  },
});
