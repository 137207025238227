
import {defineComponent} from 'vue';
import {Form, Field, ErrorMessage, configure} from 'vee-validate';
import {setNewPasswordFormSchema} from '../validation/passwordReset';
import PageTitle from '@/components/layout/PageTitle.vue';
import {useUserStore} from '../stores/UserStore';
import {validationConfigDefault} from '@/validation/config';
import PageLoader from '@/components/PageLoader.vue';
import {setNewPassword} from '@/services/api';

configure(validationConfigDefault);

export default defineComponent({
  name: 'PasswordResetView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
    PageLoader,
  },
  props: ['token'],
  setup() {
    const userStore = useUserStore();

    return {userStore, setNewPasswordFormSchema};
  },
  data: () => {
    return {
      newPassword: '',
      confirmPassword: '',
      isLoading: false,
      sent: false,
      error: '',
    };
  },
  methods: {
    async setNewPassword() {
      this.isLoading = true;
      const result = await setNewPassword(this.newPassword, this.token);
      if (result) {
        this.sent = true;
      } else {
        this.error = 'Something went wrong. Please try again later';
      }
      this.isLoading = false;
    },
  },
});
