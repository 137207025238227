import {appConfig} from '@/config';
const API_BASE_URL = appConfig.apiBaseUrl;
import axiosDefault, {isAxiosError} from 'axios';
const axios = axiosDefault.create({baseURL: API_BASE_URL, withCredentials: true});

export const httpCodes = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  DELETED: 204,
  TEMP_REDIRECT: 307,
  PERM_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORISED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  ERROR: 500,
} as const;

export interface Tack {
  id: string;
  tackName: string;
  tackNumber: string;
  tackStatus: number;
  tackSize: string;
  tackBrand: string;
  tackColour: string;
  tackType: string;
  tackDescription: string;
  tackImage: string;
  tackOwnerId: string;
}

export const loadTackRequest = async (tackId: string): Promise<Tack | undefined> => {
  try {
    const res = await fetch(API_BASE_URL + '/tack/' + tackId, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await res.json();
    const tack: Tack = data.data;

    return tack;
  } catch (e) {
    console.log(e);
  }
};

export const editTackRequest = async (id: string, changedTackDetails: any, tackMainPhoto: any) => {
  const isTackDetailsChanged = Object.keys(changedTackDetails).length !== 0;
  const isMainPhotoChanged = tackMainPhoto.value !== null;

  if (!isTackDetailsChanged && !isMainPhotoChanged) {
    return {status: true};
  }

  const formData = new FormData();
  if (isMainPhotoChanged) {
    formData.append('mainTackPhoto', tackMainPhoto.value as any);
  }
  // eslint-disable-next-line
  if (isTackDetailsChanged) {
    formData.append('editTackData', JSON.stringify(changedTackDetails));
  }

  const res = await axios.put(API_BASE_URL + '/tack/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
  });

  return res.data;
};

export type TransferRecipientError = {
  status: number;
  message: string;
};

export type TransferRecipient = {
  fullName: string;
  email: string;
  userId: string;
};

export const searchUserByEmail = async (email: string): Promise<TransferRecipient | TransferRecipientError> => {
  try {
    const data = await axios.post('user/lookup-recipient', {
      email,
    });
    const transferRecipient = data.data.user;

    return transferRecipient;
  } catch (error: any) {
    if (isAxiosError(error) && error.response?.status) {
      return {
        status: error.response.status,
        message: error.response.data.message,
      };
    }

    return {
      status: 500,
      message: error.message,
    };
  }
};

export const tackTransfer = async (tackId: string, recipientId: string): Promise<boolean> => {
  const data = await axios.post(`tack/${tackId}/transfer`, {
    recipientId,
  });
  const transferRecipient = data.data;

  return transferRecipient;
};

export const getVerificationToken = async (): Promise<string> => {
  const data = await axios.get(`user/get-verification-token`);

  const token = data.data.token;

  return token;
};

export const requestPasswordReset = async (email: string): Promise<boolean> => {
  try {
    const response = await axios.post('user/request-reset', {
      email,
    });

    return response.status === httpCodes.OK;
  } catch (e) {
    return false;
  }
};

export const setNewPassword = async (password: string, token: string): Promise<boolean> => {
  try {
    const response = await axios.post('user/password-reset', {
      password,
      token,
    });

    return response.status === httpCodes.OK;
  } catch (e) {
    return false;
  }
};
