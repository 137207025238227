import * as yup from 'yup';

export const passwordResetFormSchema = yup.object({
  userEmail: yup.string().required().email().label('Email Address'),
});

export const setNewPasswordFormSchema = yup.object({
  newPassword: yup.string().test('empty-or-8', 'Password must be at least 8 characters', (password) => !password || password.length >= 8),
  confirmPassword: yup.string().when('newPassword', {
    is: (password: string) => !!password,
    then: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .label('Confirm Password'),
  }),
});
