
import {defineComponent, computed, ref} from 'vue';
import {getImageURL, getStatusLabel, isStolen} from '@/helpers';
import TackPopup from '@/components/ui/TackPopup.vue';

export default defineComponent({
  name: 'TackListItem',
  props: ['id', 'tackNumber', 'tackName', 'tackBrand', 'tackStatus', 'tackImage', 'tackOwnerId'],
  emits: ['tackUpdated'],
  components: {
    TackPopup,
  },
  setup(props, {emit}) {
    const isPopupOpen = ref(false);

    const statusLabel = computed(() => getStatusLabel(props.tackStatus));
    const isStolenState = computed(() => isStolen(props.tackStatus));

    const emitTackUpdate = () => {
      emit('tackUpdated', props.id);
    };
    return {
      getImageURL,
      statusLabel,
      isStolen: isStolenState,
      emitTackUpdate,
      isPopupOpen,
    };
  },
});
