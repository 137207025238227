
import {defineComponent, ref} from 'vue';
import PageTitle from '@/components/layout/PageTitle.vue';
import PageLoader from '@/components/PageLoader.vue';
import {Form, Field, ErrorMessage, configure} from 'vee-validate';
import {userProfileEditFormSchema} from '@/validation/userProfile';
import {validationConfigDefault} from '@/validation/config';
import {useUserStore} from '../stores/UserStore';

configure(validationConfigDefault);

export default defineComponent({
  name: 'ProfileEditView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
    PageLoader,
  },
  async setup() {
    const isLoading = ref(false);
    const userStore = useUserStore();
    await userStore.fetchUser();

    const user = {...userStore.user};
    const details = user.details ? {...user.details} : null;

    const userValues: any = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',

      hasDetails: !!details,

      address: details ? details.address : null,
      postcode: details ? details.postcode : null,
      state: details ? details.state : null,
      suburb: details ? details.suburb : null,
      mobile: details ? details.mobile : null,
    };

    const initialUserValues = {...userValues} as any;

    return {
      isLoading,
      userStore,
      userValues,
      schema: userProfileEditFormSchema,
      alreadyExists: false,
      initialUserValues,
    };
  },
  methods: {
    async updateProfile() {
      this.isLoading = true;
      const changedUserDetails: any = {};

      for (let formEntry in this.userValues) {
        if (this.initialUserValues[formEntry] !== this.userValues[formEntry]) {
          changedUserDetails[formEntry] = this.userValues[formEntry];
        }
      }

      // No need to send `confirmPassword` to APi so removing it
      delete changedUserDetails.confirmPassword;

      const data = await this.userStore.updateProfile(changedUserDetails);

      this.isLoading = false;

      if (data && !data.id) {
        const isAuth = await this.userStore.isLoggedIn();
        if (isAuth) {
          this.$router.push('/account');
        }
      }
    },
  },
});
