import * as yup from 'yup';

export const userProfileEditFormSchema = yup.object({
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  email: yup.string().required().email().label('Email Address'),
  newPassword: yup.string().test('empty-or-8', 'Password must be at least 8 characters', (password) => !password || password.length >= 8),
  confirmPassword: yup.string().when('newPassword', {
    is: (password: string) => !!password,
    then: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .label('Confirm Password'),
  }),
  oldPassword: yup.string().when('newPassword', {
    is: (password: string) => !!password,
    then: yup.string().required().label('Old Password'),
  }),

  hasDetails: yup.boolean(),

  address: yup.string().when('hasDetails', {
    is: true,
    then: yup.string().required().label('Street Address'),
    otherwise: yup.string().nullable(),
  }),
  suburb: yup.string().when('hasDetails', {
    is: true,
    then: yup.string().required().label('Suburb'),
    otherwise: yup.string().nullable(),
  }),
  state: yup.string().when('hasDetails', {
    is: true,
    then: yup.string().required().label('State'),
    otherwise: yup.string().nullable(),
  }),
  postcode: yup.string().when('hasDetails', {
    is: true,
    then: yup
      .string()
      .matches(/([0-9]{4})/, 'Not a valid Postcode. Example: 5501')
      .required()
      .min(4)
      .max(4)
      .label('Postcode'),
    otherwise: yup.string().nullable(),
  }),
  mobile: yup.string().when('hasDetails', {
    is: true,
    then: yup.string().required().label('Mobile Number'),
    otherwise: yup.string().nullable(),
  }),
});
