<template>
  <Slide right>
    <ul id="mobile-menu" class="mobile-menu-nav">
      <li class="menu-item">
        <a href="https://tacktrack.com.au/">
          <span class="menu-item-text">
            <span class="menu-text">Home</span>
          </span>
        </a>
      </li>
      <li class="menu-item">
        <a href="https://tacktrack.com.au/features/">
          <span class="menu-item-text">
            <span class="menu-text">Features</span>
          </span>
        </a>
        <i class="next-level-button" :class="{opened: featuresOpened}" @click="openFeatures()"
          ><svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style="enable-background: new 0 0 16 16"
            xml:space="preserve"
          >
            <path
              d="M13.5,7H9V2.5c0-0.6-0.4-1-1-1s-1,0.4-1,1V7H2.5c-0.6,0-1,0.4-1,1s0.4,1,1,1H7v4.5c0,0.6,0.4,1,1,1s1-0.4,1-1V9h4.5c0.6,0,1-0.4,1-1S14.1,7,13.5,7z"
            ></path></svg
        ></i>
        <Transition name="slide">
          <ul class="sub-nav" v-if="featuresOpened">
            <li class="mobile-secondary-menu-item">
              <a href="https://tacktrack.com.au/features/">
                <span class="menu-item-text"><span class="menu-text">Features</span></span></a
              >
            </li>
            <li class="mobile-secondary-menu-item">
              <a href="https://tacktrack.com.au/faq/">
                <span class="menu-item-text"><span class="menu-text">FAQ</span></span></a
              >
            </li>
          </ul>
        </Transition>
      </li>

      <li class="menu-item">
        <a href="https://tacktrack.com.au/horse-tack-marking/">
          <span class="menu-item-text">
            <span class="menu-text">Tack Marking</span>
          </span>
        </a>
        <i class="next-level-button" :class="{opened: markingOpened}" @click="openMarking()"
          ><svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            style="enable-background: new 0 0 16 16"
            xml:space="preserve"
          >
            <path
              d="M13.5,7H9V2.5c0-0.6-0.4-1-1-1s-1,0.4-1,1V7H2.5c-0.6,0-1,0.4-1,1s0.4,1,1,1H7v4.5c0,0.6,0.4,1,1,1s1-0.4,1-1V9h4.5c0.6,0,1-0.4,1-1S14.1,7,13.5,7z"
            ></path></svg
        ></i>
        <Transition name="slide">
          <ul class="sub-nav" v-if="markingOpened">
            <li class="mobile-secondary-menu-item">
              <a href="https://tacktrack.com.au/shop/">
                <span class="menu-item-text"><span class="menu-text">Shop</span></span></a
              >
            </li>
            <li class="mobile-secondary-menu-item">
              <a href="https://tacktrack.com.au/horse-tack-marking/">
                <span class="menu-item-text"><span class="menu-text">How to mark your tack</span></span></a
              >
            </li>
            <li class="mobile-secondary-menu-item">
              <a href="https://tacktrack.com.au/find-a-tack-marker/">
                <span class="menu-item-text"><span class="menu-text">Find a Tack Marker</span></span></a
              >
            </li>
          </ul>
        </Transition>
      </li>

      <li class="menu-item">
        <a href="https://tacktrack.com.au/blog/">
          <span class="menu-item-text">
            <span class="menu-text">Blog</span>
          </span>
        </a>
      </li>
      <li class="menu-item">
        <router-link :to="{name: 'Account'}" class="login-nav-btn">
          <span class="menu-item-text">
            <span class="menu-text">My Account</span>
          </span>
        </router-link>
      </li>
      <li class="menu-item">
        <a href="https://tacktrack.com.au/contact/">
          <span class="menu-item-text">
            <span class="menu-text">Contact Us</span>
          </span>
        </a>
      </li>
      <li v-if="isLoggedIn()" class="menu-item mobile-auth-link">
        <a @click="logout()" href="#" class="login-nav-btn">
          <span><font-awesome-icon icon="fa-solid fa-right-from-bracket" /> Logout</span>
        </a>
      </li>
      <li v-if="!isLoggedIn()" class="menu-item mobile-auth-link">
        <a href="https://members.tacktrack.com.au/login/0">
          <span><font-awesome-icon icon="fa-solid fa-user" /> Login</span>
        </a>
        <a href="https://members.tacktrack.com.au/register">
          <span><font-awesome-icon icon="fa-solid fa-user" /> Register</span>
        </a>
      </li>
    </ul>
  </Slide>
  <main id="page-wrap" class="main">
    <PageHeader />
    <router-view v-slot="{Component}">
      <template v-if="Component">
        <suspense>
          <component :is="Component"></component>
          <template #fallback>
            <div
              style="
                background-color: rgba(0, 0, 0, 0.3);
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                font-size: 31px;
                text-align: center;
                z-index: 1000;
              "
            >
              <h1 style="margin-top: 40vh; color: #ffffff; text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7)">Loading...</h1>
            </div>
          </template>
        </suspense>
      </template>
    </router-view>
    <PageFooter />
  </main>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader.vue';
import PageFooter from '@/components/layout/PageFooter.vue';
import {useUserStore} from '@/stores/UserStore';

import {Slide} from 'vue3-burger-menu';

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
    Slide,
  },
  data: function () {
    return {
      featuresOpened: false,
      markingOpened: false,
      isLoggedIn() {
        const userStore = useUserStore();
        const isLoggedIn = userStore.isLoggedIn();
        return isLoggedIn;
      },
      async logout() {
        const userStore = useUserStore();
        await userStore.logout();
        this.$router.push('/login/0');
      },
    };
  },
  methods: {
    openFeatures() {
      if (!this.featuresOpened) {
        this.featuresOpened = true;
        this.markingOpened = false;
      } else {
        this.featuresOpened = false;
      }
    },
    openMarking() {
      if (!this.markingOpened) {
        this.markingOpened = true;
        this.featuresOpened = false;
      } else {
        this.markingOpened = false;
      }
    },
  },
};
</script>

<style>
@import 'https://fonts.googleapis.com/css?family=Roboto&#038;ver=6.1.1';
@import 'https://fonts.googleapis.com/css?family=Roboto:400,500,600,700|Roboto+Condensed:400,600,700';

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slide-enter-active,
.slide-leave-active {
  max-height: 150px;
}
.slide-enter-active {
  transition: all 0.5s ease-in;
}
.slide-leave-active {
  transition: all 0.5s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  max-height: 0;
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  max-height: auto;
}
</style>
