
import {defineComponent, ref, Ref} from 'vue';
import PageTitle from '@/components/layout/PageTitle.vue';
import TackListItem from '@/components/account/TackListItem.vue';
import ButtonLink from '@/components/ui/ButtonLink.vue';
import {TackStatus} from '@/constants';

import {appConfig} from '@/config';
const API_BASE_URL = appConfig.apiBaseUrl;

export default defineComponent({
  name: 'AccountView',
  components: {
    PageTitle,
    TackListItem,
    ButtonLink,
  },
  async setup() {
    const tacks: Ref = ref([]);

    const sortTacks = (tacksArray: any[]) => {
      return tacksArray.sort((a, b) => a.id.localeCompare(b.id));
    };

    const loadTacks = async () => {
      const res = await fetch(API_BASE_URL + '/tack/list', {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await res.json();
      return sortTacks([...data.data]);
    };

    const handleTackUpdate = async (updatedTackId: TackStatus) => {
      const res = await fetch(API_BASE_URL + `/tack/${updatedTackId}`, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        // stolen
        body: JSON.stringify({tackStatus: TackStatus.STOLEN}),
      });

      tacks.value = await loadTacks();
    };

    tacks.value = await loadTacks();

    return {tacks, handleTackUpdate};
  },
  data: () => {
    return {
      //isLoading: true
      //tacks: null
    };
  },
});
