
import {defineComponent} from 'vue';
import {Form, Field, ErrorMessage, configure} from 'vee-validate';
import {passwordResetFormSchema} from '../validation/passwordReset';
import PageTitle from '@/components/layout/PageTitle.vue';
import {useUserStore} from '../stores/UserStore';
import {validationConfigDefault} from '@/validation/config';
import {requestPasswordReset} from '@/services/api';
import PageLoader from '@/components/PageLoader.vue';

configure(validationConfigDefault);

export default defineComponent({
  name: 'PasswordResetView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
    PageLoader,
  },
  setup() {
    const userStore = useUserStore();

    return {userStore, passwordResetFormSchema};
  },
  data: () => {
    return {
      email: '',
      sent: false,
      error: '',
      isLoading: false,
    };
  },
  methods: {
    async requestPasswordReset() {
      this.isLoading = true;
      const result = await requestPasswordReset(this.email);
      if (result) {
        this.sent = true;
      } else {
        this.error = 'Something went wrong. Please try again later';
      }
      this.isLoading = false;
    },
  },
});
