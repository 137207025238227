import {createApp} from 'vue';
import {createPinia} from 'pinia';
import {appConfig} from '@/config';
import '@stripe/stripe-js';
import {VueRecaptchaPlugin} from 'vue-recaptcha';
import VueGtm from 'vue-gtm';

import App from './App.vue';
import router from './router';

import '@/assets/css/global.css';

const RECAPTCHA_SITE_KEY = appConfig.recaptchaSiteKey;

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
/* import specific icons */
import {faUser, faUserPlus, faRightFromBracket, faSpinner} from '@fortawesome/free-solid-svg-icons';
/* add icons to the library */
library.add(faUser, faUserPlus, faRightFromBracket, faSpinner);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(VueRecaptchaPlugin, {
  v2SiteKey: RECAPTCHA_SITE_KEY,
});

app.use(createPinia());
app.use(router);

if (appConfig.gtmId) {
  app.use(VueGtm, {
    id: appConfig.gtmId,
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
}

app.mount('#app');
