
import {defineComponent, ref} from 'vue';
import PageTitle from '@/components/layout/PageTitle.vue';
import {Form, Field, ErrorMessage, configure} from 'vee-validate';
import {userRegisterFormSchema} from '@/validation/userRegister';

import {useUserStore} from '../stores/UserStore';
import {Checkbox} from 'vue-recaptcha';
import {useRecaptchaProvider} from 'vue-recaptcha';
import {validationConfigDefault} from '@/validation/config';

configure(validationConfigDefault);

export default defineComponent({
  name: 'RegisterView',
  components: {
    PageTitle,
    Form,
    Field,
    ErrorMessage,
    Checkbox,
  },
  setup() {
    useRecaptchaProvider();
    const userStore = useUserStore();
    const reCaptchaResponse = ref();

    return {userStore, schema: userRegisterFormSchema, reCaptchaResponse};
  },
  data: () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: 'user',
      completed: false,
      alreadyExists: false,
      invalidCaptcha: false,
      userTerms: undefined,
    };
  },
  watch: {
    reCaptchaResponse() {
      const errElement = document.getElementById('reCaptchaError') as HTMLDivElement;
      if (!this.reCaptchaResponse) {
        errElement.innerText = 'Check if you is not a robot';
      } else {
        errElement.innerText = '';
      }
    },
  },
  methods: {
    async register() {
      this.alreadyExists = false;

      if (!this.reCaptchaResponse) {
        const errElement = document.getElementById('reCaptchaError') as HTMLDivElement;
        errElement.innerText = 'Check if you is not a robot';
        return false;
      }

      let data = await this.userStore.register(this.firstName, this.lastName, this.email, this.password, this.role, this.reCaptchaResponse);

      if ('invalidCaptcha' in data) {
        this.invalidCaptcha = true;
        console.log('Invalid Captcha');
        return;
      }

      if ('alreadyExists' in data && data.alreadyExists) {
        this.alreadyExists = true;
        console.log('Already Exists');
        return;
      }

      const isAuth = await this.userStore.isLoggedIn();
      if (isAuth) {
        this.completed = true;
        this.$router.push('/account');
      }
    },
  },
});
