import * as yup from 'yup';

export const userRegisterFormSchema = yup.object({
  userFirstName: yup.string().required().label('First Name'),
  userLastName: yup.string().required().label('Last Name'),
  userEmail: yup.string().required().email().label('Email Address'),
  userPassword: yup.string().required().min(8).label('Password'),
  userPasswordConfirm: yup
    .string()
    .required()
    .oneOf([yup.ref('userPassword'), null], 'Passwords must match')
    .label('Confirm Password'),
  userTerms: yup.string().required().label('Terms and Condition agreement'),
});

export const tackRegisterUserDetailsSchema = yup.object({
  userStreetAddress: yup.string().required().label('Street Address'),
  userSuburb: yup.string().required().label('Suburb'),
  userState: yup.string().required().label('State'),
  userPostcode: yup
    .string()
    .matches(/([0-9]{4})/, 'Not a valid Postcode. Example: 5501')
    .required()
    .min(4)
    .max(4)
    .label('Postcode'),
  userMobileNumber: yup
    .string()
    .matches(/^04\d{8}$/, 'Invalid mobile number. It should start with 04 and contain 10 digits')
    .required()
    .label('Mobile Number'),
  userTerms: yup.string().required().label('Terms and Condition agreement'),
});
